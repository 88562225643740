<template>
  <section id="member_login">
    <div class="main">
      <div class="wrapper">
        <DecotTitle :title="$t('memberLogin')" engTitle="WELCOME NUWA" />

        <p class="description supersmall txt-center">{{ $t("loginPage.description.tips") }}</p>
        <div class="wrapper__content">
          <re-easy-form v-model="easyForm" :rules="loginRule" :labelConfig="labelConfig" ref="reEasyForm" />

          <div class="other-options">
            <re-checkbox v-model="rememberMe" :label="this.$t('rememberMe')" />
            <span class="divide"></span>
            <router-link :to="{ name: 'ForgetPassword' }" class="forget-password">{{
              $t("fogotPassword")
            }}</router-link>
          </div>

          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="submitEasyForm">{{
              $t("memberLogin")
            }}</re-button>
          </div>
          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="signUp">{{
              $t("signUp")
            }}</re-button>
            <!-- <re-button @click="openDialog">開啟彈窗</re-button> -->
          </div>
        </div>
      </div>
    </div>

    <re-dialog :visible.sync="emailValidateDialogStatus" footerPosition="center" :template="EmailValidateRemind"
      triggerCallbackOnOverlay :callback="callback" />
  </section>
</template>

<script>
import '@/assets/scss/login.scss';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
import ReButton from '@/components/form/ReButton.vue';
import ReCheckbox from '@/components/form/ReCheckbox.vue';
import { mapMutations, mapState, mapGetters } from 'vuex';
import { newLogIn, getMemberProfileV2 /* changePassword */ } from '@/lib/cakeHttp';
import DecotTitle from '@/components/global/DecoTitle.vue';
import { loginRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import VueCookies from 'vue-cookies';
import { getLangCode } from '@/utils/getLangCode';
import dayjs from 'dayjs';
import EmailValidateRemind from '@/components/dialog/content/EmailValidateRemind.vue';
import ReDialog from '@/components/dialog/ReDialog.vue';
import cusStorage from '@/utils/cusStorage';

export default {
  name: 'NewLogin',
  components: {
    ReEasyForm,
    ReButton,
    ReCheckbox,
    DecotTitle,
    ReDialog
  },
  data() {
    return {
      EmailValidateRemind,
      emailValidateDialogStatus: false,
      dialogData: {
        type: 'email'
      },
      formData: {
        account: '',
        password: ''
      },
      // ...
      rememberMe: false,
      radioOptions: [
        {
          label: this.$t('rememberMe'),
          value: true
        }
      ],
      easyForm: [
        {
          label: this.$t('accout'),
          prop: 'account',
          value: '',
          comp: 'input',
          placeholder: this.$t('loginAccountPlaceholder')
        },
        {
          label: this.$t('password'),
          prop: 'password',
          value: '',
          type: 'password',
          comp: 'input',
          placeholder: this.$t('loginPasswordPlaceholder')
        }
      ],
      loginRule,
      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    ...mapState(['member', 'isInAppointementProgress', 'recordLatestPage']),
    ...mapGetters(['getMemberAll']),
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;
        return obj;
      }, {});
    }
  },
  methods: {
    ...mapMutations([
      'logIn',
      // 'toggleShowVerifyWarning',
      'toggleShowPrivacyWarning',
      'toggleShowMsg',
      'setMsgInfo',
      'logOut',
      'setIsInAppointementProgress',
      'setRecordLatestPage'
    ]),
    callback() {
      this.$router.push({
        name: 'Home'
      });
    },
    handleEmailValidateDialogStatus(status) {
      this.emailValidateDialogStatus = status;
    },
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();
      if (checkForm.status) {
        this.clickLogIn();
      }
    },

    signUp() {
      this.$router.push({ name: 'Signup' });
    },
    clickLogIn() {
      newLogIn(
        {
          ...this.filterData,
          type: 'web',
          lang_id: getLangCode()
        },
        // 成功登入
        ({ data }) => {
          console.log('this.rememberMe', this.rememberMe);
          if (this.rememberMe) {
            const { account } = this.filterData;
            cusStorage.setItem('loginInfo', { account });
          } else {
            cusStorage.removeItem('loginInfo');
          }

          this.logIn(data);
          // 過渡版 不驗證
          // const { email_verified_at, agree_privicy } = data.member;

          // // 隱私權未同意跳隱私權
          // if (!agree_privicy) {
          //   this.toggleShowPrivacyWarning(true);

          //   return;
          // }

          // // 沒驗證 email 跳彈窗
          // if (!email_verified_at) {
          //   // this.dialogData.type = 'email';
          //   this.toggleShowVerifyWarning({ status: true, type: 'email' });
          //   return;
          // }

          if (this.isInAppointementProgress) {
            this.setIsInAppointementProgress(false);
            this.$router.push({
              name: 'RegistrationConfirm'
            });
            return;
          }

          if (this.recordLatestPage) {
            this.$router.push({
              name: 'MemberHome'
            });

            this.setRecordLatestPage(null);
            return;
          }

          this.$router.push({ name: 'Home' });
        },
        // 登入失敗
        (err) => {
          console.log('err.', err);
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
          // this.$customSWAL({ icon: 'error', title: this.$t('loginError') });
        }
      );
    },
    openLightbox() {
      this.changePasswordLightboxOpen = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox() {
      this.changePasswordLightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    getMemberData() {
      getMemberProfileV2(
        (data) => {
          const { token } = this.$route.query;
          // login 結構與直接拿結構不同，所以多套一層

          this.logIn({
            member: {
              ...data,
              token
            }
          });

          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('emailValidateSucceed')
          });

          this.$router.push({
            name: 'MemberData'
          });
          // window.location = window.location.origin;
        },
        () => { }
      );
    },
    autoLogin() {
      const { token } = this.$route.query;

      if (token) {
        VueCookies.set('api_token', token);
        this.getMemberData();
      }
    },
    checkQuery() {
      const { token, exp_time } = this.$route.query;

      if (token && exp_time) {
        const expireTimestamp = Number(exp_time) * 1000;
        const expiredTime = dayjs(expireTimestamp).add(30, 'm');
        const curr = dayjs(Date.now());
        const result = curr.diff(expiredTime, 's');

        // 若過期，跳出提示
        if (result > 0) {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: this.$t('linkExpired')
          });
        } else {
          // 若沒過期，自動登入
          this.autoLogin();
        }
      } else {
        // 若沒有 query，檢查是否已登入，若已登入，踢去首頁
        this.checkIsLogin();
      }
    },
    checkIsLogin() {
      const { action } = this.$route.query;

      // app 來的
      if (action === 'emailValidate') {
        this.logOut();
        this.handleEmailValidateDialogStatus(true);

        // this.$router.push({
        //   name: 'MemberData'
        // });
        return;
      }

      if (this.getMemberAll.api_token) {
        this.$router.push({
          name: 'Home'
        });
      }

      this.handleRememberMe();
    },
    handleRememberMe() {
      const loginInfo = cusStorage.getItem('loginInfo');
      console.log('loginInfo', loginInfo);

      if (loginInfo) {
        this.easyForm = this.easyForm.map((item) => {
          item.value = loginInfo[item.prop];

          return item;
        });

        this.rememberMe = true;
      }
    }
  },
  created() {
    this.checkQuery();

    // this.getMemberData();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/other/color.scss";
@import "@/assets/scss/other/mixin.scss";

.description {
  margin-bottom: 30px;
  padding: 0 12%;
  color: $c-text1;
}

.wrapper {
  width: 100%;
  max-width: 450px;

  &__content {
    max-width: 380px;
    margin: 0 auto;
  }
}

.btn-wrap {
  &+& {
    margin-top: 20px;
  }
}

.other-options {
  // margin: 0 auto;
  text-align: center;
  margin: 20px 0;
  @include flex(center);
}

.divide {
  @include font-style($c-grey1, 14);
  position: relative;

  &::after {
    content: "";
    @include position(center, 0, 0);
    width: 1px;
    height: 15px;
    background-color: $c-grey1;
  }
}

.forget-password {
  @include font-style($c-grey1, 14);
  margin: 5px 10px;
}

@media screen and (max-width: 425px) {
  .description {
    padding: 0 5%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 90%;

    &__content {
      max-width: 90%;
    }
  }
}
</style>
