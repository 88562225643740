<template>
  <div class="c-email-validate-remind">
    <div class="c-email-validate-remind__content">

      <div class="status">
          <img class="status__icon" src="@/assets/icons/warning.svg" />
        </div>

      <p class="text">{{ $t('pleaseLoginAgain')}}</p>

      <div class="btn-wrap">
        <!-- <div class="btn-wrap__btn">
          <re-button round="round" type="border" @click="cancel" :style="{ width: '120px' }">
            {{$t('cancel')}}
          </re-button>
        </div> -->
          <div class="btn-wrap__btn">
          <re-button round="round" type="border" @click="confirm" :style="{ width: '120px' }">
            {{$t('confirm')}}
          </re-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'EmailVAlidateRemind',
  components: {
    ReButton
  },
  props: {
    type: {
      type: String
    }
  },
  computed: {
    ...mapState(['member']),
    account() {
      return this.member.account;
    }
  },
  methods: {
    ...mapMutations([
      'logOut',
    ]),
    cancel() {
      this.$router.push({
        name: 'Home'
      });
      this.$emit('close');
    },
    confirm() {
      this.logOut();
      this.$emit('onlyClose');
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-email-validate-remind {
  @include padding(30px);
}

.status {
  @include padding(0 0 20px);
  @include flex(center);

  &__icon {
    width: 50px;
  }
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);

  &__btn {
    & + & {
      margin-left: 10px;
    }
  }
}

.text {
  @include font-style($c-black, 16, 400, 1px, 24px);
  text-align: center;
}
</style>
